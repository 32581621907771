/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import {
  viewByDateUserPaymentlink,
  viewByUserPaymentlink,
} from "../../Api/Paymentlink";
import moment from "moment";
import { CreteToken, StatusOrder } from "../../Api/order";
import Modal from "react-modal";
import { IoMdClose } from "react-icons/io";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PaymentLink = () => {
  const [inputs, setInputs] = useState({
    from: "",
    to: "",
  });
  const [errorlist, seterrorlist] = useState(null);
  const [paymentdata, setpaymentdata] = useState([]);
  const [viewmodalIsOpen, setviewmodalIsOpen] = useState(false);
  const [paymentdetails, setpaymentdetails] = useState([]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const filterbtn = async () => {
    const newerrors = {};
    if (inputs.from.length === 0) {
      newerrors.from = "Please Select From date...";
    }
    if (inputs.to.length === 0) {
      newerrors.to = "Please Select To date...";
    }
    seterrorlist(newerrors);
    if (Object.keys(newerrors).length === 0) {
      var userid = sessionStorage.getItem("user_id");
      inputs["id"] = userid;
      var viewdetails = await viewByDateUserPaymentlink(inputs);
      setpaymentdata(viewdetails);
    }
  };
  useEffect(() => {
    getmypaymentlink();
  }, []);
  const getmypaymentlink = async () => {
    var userid = sessionStorage.getItem("user_id");
    var allmydata = await viewByUserPaymentlink({ id: userid });
    setpaymentdata(allmydata);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(50);
  const indexofLastPost = currentPage * postsPerPage;
  const indexofFirstPost = indexofLastPost - postsPerPage;
  const currentPost = paymentdata.slice(indexofFirstPost, indexofLastPost);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(paymentdata.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  const clearbtn = () => {
    getmypaymentlink();
    setInputs((values) => ({ ...values, from: "" }));
    setInputs((values) => ({ ...values, to: "" }));
    document.getElementById("from").value = "";
    document.getElementById("to").value = "";
  };
  const vieworder = async (data) => {
    toast.info("Please Wait...", {
      autoClose: 2000,
      transition: Slide,
    });
    var newdata = {
      client_id: process.env.REACT_APP_CLIENT_ID,
      username: process.env.REACT_APP_USER_NAME,
      password: process.env.REACT_APP_PASSWORD,
    };
    var cretetoken = await CreteToken(newdata);
    if (cretetoken.status === "SUCCESS") {
      var datanew = {
        orderid: data.orderid,
        client_id: process.env.REACT_APP_CLIENT_ID,
        token: cretetoken.data.token,
      };
      var statusorder = await StatusOrder(datanew);
      if (statusorder.STATUS === "SUCCESS") {
        setviewmodalIsOpen(true);
        setpaymentdetails([statusorder]);
      }
    }
  };
  const closeModalnew = () => {
    setviewmodalIsOpen(false);
  };
  return (
    <div>
      <h1 className="text-start font-bold text-xl mt-5">Payment Links</h1>
      <div className="bg-white flex flex-col px-5 py-5 ">
        <label className="text-left py-1 font-bold">From</label>
        <div>
          <div className="flex rounded justify-between items-center px-4 border border-black w-72">
            <input
              className="p-2 outline-none w-full"
              placeholder=""
              alt=""
              type="date"
              name="from"
              onChange={handleChange}
              id="from"
            />
          </div>
          {errorlist !== null ? (
            <div className="text-red-500 text-start">{errorlist.from}</div>
          ) : null}
        </div>
        <div className="flex mt-2 px-1">
          <label className="py-1 font-bold">To</label>
        </div>
        <div className="flex rounded justify-between items-center px-4 border border-black w-72">
          <input
            className="p-2 outline-none w-full "
            placeholder=""
            alt=""
            type="date"
            name="to"
            id="to"
            onChange={handleChange}
          />
        </div>
        {errorlist !== null ? (
          <div className="text-red-500 text-start">{errorlist.to}</div>
        ) : null}
        <div className="w-full my-2 text-start">
          <button
            className="bg-[#007DFA] my-4 text-sm font-bold w-32 h-10 rounded text-white mr-5"
            onClick={filterbtn}
          >
            Filter
          </button>
          <button
            className="bg-[red] my-2 text-sm font-bold w-32 h-10 rounded text-white"
            onClick={clearbtn}
          >
            Clear
          </button>
        </div>
      </div>
      <table className="w-full text-sm text-center items-center border border-black rounded bg-white hover:shadow-gray-500/20">
        <tr className="bg-black text-white text-sm font-light w-full">
          <th className="px-10 py-3">#</th>
          <th className="px-10 py-3">
            <p>Date & Time</p>
          </th>
          <th className="px-10 py-3">
            <p>Email / Phone</p>
          </th>
          <th className="px-10 py-3">
            <p>Transaction id</p>
          </th>
          <th className="px-10 py-3">
            <p>Amount</p>
          </th>
          <th className="px-10 py-3">
            <p>Status</p>
          </th>
        </tr>
        {currentPost.length !== 0 ? (
          currentPost.map((data, index) => (
            <tr className="py-3 border border-black" key={index}>
              <td className="py-2 font-semibold border border-black">
                {index + 1}
              </td>
              <td className="py-2 font-semibold border border-black">
                {moment(data.createdAt).format("DD-MM-YYYY , MM:HH")}
              </td>
              <td className="py-2 font-semibold border border-black">
                {data.phone.length == 0 ? "-" : data.phone}{" "}
              </td>
              <td className="py-2 font-semibold border border-black">
                {data.orderid == null ? "-" : data.orderid}
              </td>
              <td className="py-2 font-semibold border border-black">
                {data.amount}{" "}
              </td>

              <td className="py-2 font-semibold border border-black">
                {data.status === "Pending" ? (
                  <button className="bg-yellow-500 rounded p-2 w-28 font-semibold text-white uppercase">
                    {data.status}
                  </button>
                ) : data.status === "Success" ? (
                  <button
                    onClick={() => {
                      vieworder(data);
                    }}
                    className="bg-green-600 rounded p-2 w-28 font-semibold text-white uppercase"
                  >
                    {data.status}
                  </button>
                ) : (
                  <button className="bg-red-600 rounded p-2 w-28 font-semibold text-white uppercase">
                    {data.status}
                  </button>
                )}
              </td>
            </tr>
          ))
        ) : (
          <tr className=" py-3">
            <td className="py-2">.</td>
            <td className="py-2"> </td>
            <td className="py-2"> </td>
            <td className="py-2">No data Available</td>
            <td className="py-2 "></td>
            <td className="py-2"></td>
            <td className="py-2"></td>
            <td></td>
            <td></td>
          </tr>
        )}
      </table>
      {pageNumbers.length > 1 ? (
        <div className="row mt-5">
          <ul className="pagination">
            {pageNumbers.map((number) => (
              <li
                key={number}
                className={`page-item ${
                  currentPage === number ? "active" : ""
                }`}
                style={{ cursor: "pointer" }}
              >
                <span
                  onClick={() => setCurrentPage(number)}
                  className="page-link"
                >
                  {number}
                </span>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
      <Modal
        isOpen={viewmodalIsOpen}
        onRequestClose={closeModalnew}
        className="itemmodelnew"
      >
        <div className="flex justify-between text-lg py-2">
          <h2 className="font-medium text-md ">Transaction View</h2>
          <button onClick={closeModalnew}>
            <IoMdClose className="w-6 h-6" />
          </button>
        </div>
        <hr />
        <div className="flex justify-around py-5">
          <div>
            <h1 className="font-semibold mt-4">Amount</h1>
            <h1 className="font-semibold mt-4">Order Id</h1>
            <h1 className="font-semibold mt-4">Transaction Mode</h1>
            <h1 className="font-semibold mt-4">Transaction Id</h1>
            <h1 className="font-semibold mt-4">Customer Phone</h1>
            <h1 className="font-semibold mt-4">Customer Email</h1>
          </div>
          {paymentdetails.length !== 0 ? (
            <div>
              <h1 className="font-semibold mt-4">
                : ₹ {paymentdetails[0].ORDERSTATUS.TXN_AMOUNT} /-
              </h1>
              <h1 className="font-semibold mt-4">
                : {paymentdetails[0].ORDERSTATUS.ORDER_ID}
              </h1>
              <h1 className="font-semibold mt-4">
                : {paymentdetails[0].ORDERSTATUS.TXN_MODE}
              </h1>
              <h1 className="font-semibold mt-4">
                : {paymentdetails[0].ORDERSTATUS.BANK_TXNID}
              </h1>
              <h1 className="font-semibold mt-4">
                : {paymentdetails[0].ORDERSTATUS.CUSTOMER_PHONE}
              </h1>
              <h1 className="font-semibold mt-4">
                : {paymentdetails[0].ORDERSTATUS.CUSTOMER_EMAIL}
              </h1>
            </div>
          ) : null}
        </div>
        <hr />
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default PaymentLink;
