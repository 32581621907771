/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import Layout from "./pages/Layout";
import Login from "./pages/Login";
import { checkuserpaymentlink, updatePaymentlink } from "./Api/Paymentlink";
import { CreteToken, StatusOrder } from "./Api/order";
import { checkByUserPaymenthistory } from "./Api/paymenthistory";
import { updateUser, viewByidUser } from "./Api/User";
import { useDispatch } from "react-redux";
import { storeAction } from "./Store/Store";
import { createHistory } from "./Api/History";
import { updateDistributor, viewByidDistributor } from "./Api/distributor";
import moment from "moment";
import { CreteTransaction } from "./Api/transaction";

function App() {
  const dispatch = useDispatch();
  const didMountRef = useRef(true);

  var [userid, setuserid] = useState(null);

  useEffect(() => {
    var userid = sessionStorage.getItem("user_id");
    setuserid(userid);
    getorderdata();
    didMountRef.current = false;
  }, [userid]);

  const getorderdata = async () => {
    var userid = sessionStorage.getItem("user_id");
    if (userid !== null) {
      var viewuser = await viewByidUser({ id: userid });
      if (viewuser.length !== 0) {
        dispatch(storeAction.balanceHandler({ balance: viewuser[0].balance }));
        var allmydata = await checkuserpaymentlink({ id: userid });
        if (allmydata.length !== 0) {
          var tokenData = {
            client_id: process.env.REACT_APP_CLIENT_ID,
            username: process.env.REACT_APP_USER_NAME,
            password: process.env.REACT_APP_PASSWORD,
          };
          var cretetoken = await CreteToken(tokenData);
          if (cretetoken.status === "SUCCESS") {
            for (const dataindex of allmydata) {
              console.log(dataindex, "dataindex");
              await processPaymentLink(
                dataindex,
                userid,
                cretetoken.data.token
              );
            }
          }
        }
        setTimeout(() => {
          getorderdata();
        }, 5000);
      }
    }
  };

  const processPaymentLink = async (dataindex, userid, token) => {
    try {
      var datanew = {
        orderid: dataindex.orderid,
        client_id: process.env.REACT_APP_CLIENT_ID,
        token: token,
      };
      var statusorder = await StatusOrder(datanew);
      if (
        statusorder.ORDERSTATUS &&
        statusorder.ORDERSTATUS.STATUS === "SUCCESS"
      ) {
        var currentUser = await viewByidUser({ id: userid });
        if (currentUser.length === 0) {
          return;
        }
        var checkuser = await checkByUserPaymenthistory({
          id: userid,
          orderid: statusorder.ORDERSTATUS.ORDER_ID,
        });

        if (checkuser.length === 0) {
          return;
        }

        var newBalance =
          Number(currentUser[0].balance) + Number(checkuser[0].amount);
        var historydata = {
          user_id: userid,
          opening_bal: currentUser[0].balance,
          closing_bal: newBalance,
          amount: checkuser[0].amount,
          type: "paymentlink",
        };

        if (currentUser[0].dis_id !== null) {
          var viewdistributor = await viewByidDistributor({
            id: currentUser[0].dis_id,
          });
          if (viewdistributor.length === 0) {
            return;
          }
          var newdistributordata = {
            id: viewdistributor[0].id,
            balance:
              Number(viewdistributor[0].balance) +
              Number(checkuser[0].distributoramount),
          };
          await updateDistributor(newdistributordata);
        }
        var transactionData = {
          orderid: statusorder.ORDERSTATUS.ORDER_ID,
          date: moment().format("YYYY-MM-DD HH:MM:SS"),
          status: "Success",
          mode: null,
          urn_no: null,
          Trans_date: moment().format("YYYY-MM-DD HH:MM:SS"),
          user: userid,
          name: dataindex.name,
          user_id: userid,
          opening_bal: currentUser[0].balance,
          closing_bal: newBalance,
          amount: checkuser[0].amount,
        };
        await CreteTransaction(transactionData);
        await createHistory(historydata);
        await updateUser({
          id: userid,
          balance: newBalance,
        });
        await updatePaymentlink({ id: dataindex.id, status: "Success" });
      } else if (statusorder.ORDERSTATUS.STATUS === "FAILED") {
        await updatePaymentlink({ id: dataindex.id, status: "Failed" });
      } else {
        await updatePaymentlink({ id: dataindex.id, status: dataindex.status });
      }
    } catch (error) {
      await updatePaymentlink({ id: dataindex.id, status: "Failed" });
    }
  };

  return <>{userid === null ? <Login /> : <Layout />}</>;
}

export default App;
