/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { viewByUserHistory } from "../Api/History";
import moment from "moment";

const Historydata = () => {
  const [alldata, setalldata] = useState([]);
  useEffect(() => {
    Gethistorydata();
  }, []);
  const Gethistorydata = async () => {
    var userid = sessionStorage.getItem("user_id");
    var myhistorydata = await viewByUserHistory({ id: userid });
    const uniqueData = myhistorydata.filter(
      (item, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.amount === item.amount &&
            t.closing_bal === item.closing_bal &&
            t.opening_bal === item.opening_bal &&
            t.type === item.type
        )
    );

    // Sort the data in descending order by 'createdAt'
    uniqueData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    setalldata(uniqueData);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(100);
  const indexofLastPost = currentPage * postsPerPage;
  const indexofFirstPost = indexofLastPost - postsPerPage;
  const currentPost = alldata.slice(indexofFirstPost, indexofLastPost);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(alldata.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <div className="mr-5 w-full px-5 py-5">
      <table className="w-full border border-black rounded-md bg-white hover:shadow-gray-500/20">
        <tr className="bg-black text-white text-sm font-light w-full">
          <th className="px-6=2 py-2">#</th>
          <th className="px-2 py-2">
            <p>Date & Time</p>
          </th>
          <th className="px-2 py-2">
            <p>Retailer Name & ID</p>
          </th>
          <th className="px-2 py-2">
            <p>Transaction Type</p>
          </th>
          {/* <th className="px-2 py-2">
            <p>Transaction Id</p>
          </th> */}
          <th className="px-2 py-2">
            <p>Mobile Number</p>
          </th>
          <th className="px-6=2 py-2">
            <p>Status</p>
          </th>
          <th className="px-2 py-2">
            <p>Remarks</p>
          </th>
          <th className="px-2 py-2">
            <p>Opening Balance</p>
          </th>
          <th className="px-2 py-2">
            <p>Credit Amount</p>
          </th>
          <th className="px-2 py-2">
            <p>Debit Amount</p>
          </th>
          <th className="px-2 py-2">
            <p>Charges</p>
          </th>
          <th className="px-2 py-2">
            <p>Closing Balance</p>
          </th>
        </tr>
        {currentPost.length !== 0
          ? currentPost.map((data, index) => (
              <tr className="py-3 border border-black" key={index}>
                <td className="py-2 px-2 font-semibold border border-black">
                  {index + 1}
                </td>
                <td className="py-2 font-semibold border border-black text-sm">
                  {moment(data.createdAt).format("DD-MM-YYYY, h:mm:ss")}
                </td>
                <td className="py-2 font-semibold border border-black text-sm">
                  {data.User.name}-{data.User.merchant_ID}
                </td>
                <td className="py-2 font-semibold border border-black text-sm">
                  {data.type == "paymentlink"
                    ? "PG OR PAYIN"
                    : data.type == "payout"
                    ? "PG PAYOUT"
                    : data.type == "recharge"
                    ? "Recharge"
                    : data.type == "bbps"
                    ? data.typename
                    : "BANK ACCOUNT VERIFICATION"}
                </td>
                <td className="py-2 font-semibold border border-black text-sm">
                  {data.User.phone}
                </td>
                <td className="py-2 font-semibold border border-black text-sm">
                  {data.status == "Pending"
                    ? data.status
                    : data.status == "Refund"
                    ? data.status
                    : "Success"}
                </td>
                <td className="py-2 font-semibold border border-black text-sm">
                  -
                </td>
                <td className="py-2 font-semibold border border-black text-sm">
                  {Number(data.opening_bal).toFixed(2)}
                </td>
                <td className="py-2 font-semibold border border-black text-sm">
                  {data.type == "paymentlink"
                    ? Number(data.amount).toFixed(2)
                    : data.type == "payout"
                    ? "-"
                    : data.status == "Refund"
                    ? Number(data.amount).toFixed(2)
                    : "-"}
                </td>
                <td className="py-2 font-semibold border border-black text-sm">
                  {data.type == "paymentlink"
                    ? "-"
                    : data.type == "payout"
                    ? Number(data.amount).toFixed(2)
                    : data.type == "recharge" && data.status !== "Refund"
                    ? Number(data.amount).toFixed(2)
                    : data.type == "bbps" && data.status !== "Refund"
                    ? Number(data.amount).toFixed(2)
                    : "-"}
                </td>
                <td className="py-2 font-semibold border border-black text-sm">
                  {data.type == "paymentlink"
                    ? "-"
                    : data.type == "payout"
                    ? "-"
                    : data.type == "recharge"
                    ? "-"
                    : data.type == "bbps"
                    ? "-"
                    : Number(data.amount).toFixed(2)}
                </td>
                <td className="py-2 font-semibold border border-black text-sm">
                  {Number(data.closing_bal).toFixed(2)}
                </td>
              </tr>
            ))
          : null}
      </table>
      {pageNumbers.length > 1 ? (
        <div className="row mt-5">
          <ul className="pagination">
            {pageNumbers.map((number) => (
              <li
                key={number}
                className={`page-item ${
                  currentPage === number ? "active" : ""
                }`}
                style={{ cursor: "pointer" }}
              >
                <span
                  onClick={() => setCurrentPage(number)}
                  className="page-link"
                >
                  {number}
                </span>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default Historydata;
